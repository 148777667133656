import React from 'react';
import { Text } from '@/components/typography';
import { VALIDATION_MESSAGES } from '@/features/forms/validation';

export interface FeedbackErrorProps {
  error?: string;
}

const FeedbackError = ({ error, ...props }: FeedbackErrorProps) => {
  return (
    (error && (
      <Text size="sm" color={'destructive_400'} as="span">
        {error === 'Required' ? VALIDATION_MESSAGES.required : error}
      </Text>
    )) ||
    null
  );
};

export default FeedbackError;
