import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Element, Message } from './Banner.styles';
import { Text } from '@/components/typography';
import CheckIcon from '@/components/icons/CheckIcon/CheckIcon';
import InfoIcon from '@/components/icons/InfoIcon/InfoIcon';
import WarningCircleIcon from '@/components/icons/WarningCircleIcon/WarningCircleIcon';
import ButtonClose from '@/components/button/ButtonClose/ButtonClose';
import { SVGIconType } from '@/components/icons/svg-icon-types';

export interface BannerProps {
  show: boolean;
  appearance: 'error' | 'success' | 'info';
  showIcon?: boolean;
  showClose?: boolean;
  closeTimeout?: number;
  size?: 'base' | 'large';
  message?: string | null;
  className?: string;
  children?: ReactNode;
}
const BannerIcon = ({
  appearance = 'success',
  ...props
}: { appearance: 'error' | 'success' | 'info' } & SVGIconType) => {
  switch (appearance) {
    case 'success':
      return <CheckIcon color="success_800" {...props} />;
    case 'info':
      return <InfoIcon color="info_800" {...props} />;
    case 'error':
      return <WarningCircleIcon color="destructive_800" {...props} />;
    default:
      return null;
  }
};

const Banner = ({
  show: showProp,
  appearance,
  message,
  showIcon = true,
  showClose = false,
  size,
  closeTimeout,
  children,
  ...props
}: BannerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const show = useMemo(() => showProp, [showProp]);
  const [destroy, setDestroy] = useState(false);

  useEffect(() => {
    if (closeTimeout) {
      setTimeout(() => {
        setDestroy(() => true);
      }, closeTimeout);
      console.log(`closeTimeout`, closeTimeout);
    }
  }, [closeTimeout]);

  if (!show) return null;
  if (!message && !children) return null;
  if (destroy) return null;

  return (
    <Element ref={ref} appearance={appearance} size={size} {...props} role="alert">
      {(showIcon && <BannerIcon appearance={appearance} width={size === 'large' ? 64 : 16} />) || null}
      <Message>
        {(message && (
          <Text weight="bold" size="sm">
            {message}
          </Text>
        )) ||
          null}
        {children}
      </Message>
      {(showClose && (
        <ButtonClose
          onClick={() => {
            // setShow((prevState) => !prevState);
            // ref.current?.remove();
            setDestroy((prevState) => !prevState);
            document.body.focus();
          }}
        />
      )) ||
        null}
    </Element>
  );
};

export default Banner;
