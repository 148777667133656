import React from 'react';
import theme from '@/styles/theme';
import { SVGIconType } from '@/components/icons/svg-icon-types';

export interface XIconProps extends SVGIconType {}

const XIcon = ({ width = 24, color, ...props }: XIconProps) => {
  let defaultColor = color ? theme.color[color] : 'black';
  let height = props.height || width;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2806 18.2193C19.3503 18.289 19.4056 18.3717 19.4433 18.4628C19.481 18.5538 19.5004 18.6514 19.5004 18.7499C19.5004 18.8485 19.481 18.9461 19.4433 19.0371C19.4056 19.1281 19.3503 19.2109 19.2806 19.2806C19.2109 19.3502 19.1282 19.4055 19.0372 19.4432C18.9461 19.4809 18.8485 19.5003 18.75 19.5003C18.6514 19.5003 18.5539 19.4809 18.4628 19.4432C18.3718 19.4055 18.289 19.3502 18.2194 19.2806L12 13.0602L5.78061 19.2806C5.63988 19.4213 5.44901 19.5003 5.24999 19.5003C5.05097 19.5003 4.8601 19.4213 4.71936 19.2806C4.57863 19.1398 4.49957 18.949 4.49957 18.7499C4.49957 18.5509 4.57863 18.36 4.71936 18.2193L10.9397 11.9999L4.71936 5.78055C4.57863 5.63982 4.49957 5.44895 4.49957 5.24993C4.49957 5.05091 4.57863 4.86003 4.71936 4.7193C4.8601 4.57857 5.05097 4.49951 5.24999 4.49951C5.44901 4.49951 5.63988 4.57857 5.78061 4.7193L12 10.9396L18.2194 4.7193C18.3601 4.57857 18.551 4.49951 18.75 4.49951C18.949 4.49951 19.1399 4.57857 19.2806 4.7193C19.4213 4.86003 19.5004 5.05091 19.5004 5.24993C19.5004 5.44895 19.4213 5.63982 19.2806 5.78055L13.0603 11.9999L19.2806 18.2193Z"
        fill={defaultColor}
      />
    </svg>
  );
};

export default XIcon;
