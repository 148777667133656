import React, { MouseEvent, ReactNode } from 'react';
import { Element } from './ButtonClose.styles';
import XIcon from '@/components/icons/XIcon/XIcon';
import { DefaultTheme } from 'styled-components';

export interface ButtonCloseProps {
  className?: string;
  children?: ReactNode;
  size?: 24 | 32;
  color?: keyof DefaultTheme['color'];
  onClick: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const ButtonClose = ({ size = 24, color, onClick, ...props }: ButtonCloseProps) => {
  return (
    <Element aria-label="Close" onClick={onClick} {...props}>
      <XIcon width={size} height={size} color={color} />
    </Element>
  );
};

export default ButtonClose;
