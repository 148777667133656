import styled, { css } from 'styled-components';
import { BannerProps } from './Banner';

export const Element = styled.div<Pick<BannerProps, 'appearance' | 'size'>>`
  ${({ theme, appearance, size, ...props }) => {
    return css`
      border-radius: ${theme.radius.xs};
      padding: 0.625rem 2.5rem 0.625rem 0.625rem;

      display: flex;
      align-items: center;
      gap: ${theme.spacing.sm};

      position: relative;

      ${size === 'large' &&
      css`
        flex-direction: column;
      `};

      > button {
        position: absolute;
        right: 0;
        top: 0;
      }

      > svg {
        flex-shrink: 0;
      }

      // appearance success
      ${appearance === 'success' &&
      css`
        background: ${theme.color.success_100};
        color: ${theme.color.success_800};
      `};

      // appearance info
      ${appearance === 'info' &&
      css`
        background: ${theme.color.info_100};
        color: ${theme.color.info_800};
      `}

      // appearance error
      ${appearance === 'error' &&
      css`
        background: ${theme.color.destructive_100};
        color: ${theme.color.destructive_800};
      `};
    `;
  }};
`;

export const Message = styled.div`
  ${({ theme, ...props }) => {
    return css`
      //
    `;
  }};
`;
