import React from 'react';
import { InputElement } from '@/components/form/TextField/TextField.styles';
import cx from 'classnames';
import FeedbackError from '@/components/form/FeedbackError/FeedbackError';
import HelperText from '@/components/form/HelperText/HelperText';

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  className?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  bgIcon?: 'search';
  error?: string;
  autoCapitalize?: 'off';
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  min?: React.InputHTMLAttributes<HTMLInputElement>['min'];
  max?: React.InputHTMLAttributes<HTMLInputElement>['max'];
  maxLenght?: React.InputHTMLAttributes<HTMLInputElement>['maxLength'];

  sizing?: 'sm' | 'base' | 'lg';
  helperText?: string;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ name, type, placeholder, error, className, sizing = 'base', helperText, ...props }: TextFieldProps, ref) => {
    return (
      <>
        <InputElement
          ref={ref}
          name={name}
          type={type || 'text'}
          placeholder={placeholder}
          className={cx(
            {
              'is-invalid': error,
            },
            className
          )}
          sizing={sizing}
          {...props}
        />
        <FeedbackError error={error} />
        <HelperText text={helperText} />
      </>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
