import React from 'react';
import { Text } from '@/components/typography';

export interface HelperTextProps {
  text?: string;
}

const HelperText = ({ text, ...props }: HelperTextProps) => {
  return (
    (text && (
      <Text size="xs" color={'neutral_500'} as="span">
        {text}
      </Text>
    )) ||
    null
  );
};

export default HelperText;
