import styled, { css } from 'styled-components';
import { ButtonCloseProps } from './ButtonClose';

export const Element = styled.button<ButtonCloseProps>`
  ${({ theme, ...props }) => {
    return css`
      padding: ${theme.spacing.sm};
      margin-right: ${`${theme.spacing.xs}`};
    `;
  }};
`;
