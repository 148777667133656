import React from 'react';
import styled, { css } from 'styled-components';
import { TextFieldProps } from '@/components/form/TextField/TextField';
import { helperTextUtility, inputBaseUtility, validationPseudoClassUtility } from '@/styles/utilities';

export const InputElement = styled.input<TextFieldProps>`
  ${({ theme, bgIcon, sizing, ...props }) => {
    return css`
      // Base Style
      ${inputBaseUtility};

      // Background Icons
      ${bgIcon === 'search' &&
      css`
        ,
        &:hover {
          background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M21.5306%2020.4691L16.8365%2015.776C18.1971%2014.1426%2018.8755%2012.0475%2018.7307%209.92666C18.5859%207.8058%2017.629%205.8224%2016.0591%204.38907C14.4892%202.95575%2012.4271%202.18284%2010.3019%202.23114C8.17663%202.27944%206.15181%203.14523%204.64864%204.6484C3.14547%206.15157%202.27968%208.17638%202.23138%2010.3016C2.18308%2012.4269%202.95599%2014.489%204.38932%2016.0589C5.82265%2017.6288%207.80604%2018.5856%209.92691%2018.7304C12.0478%2018.8752%2014.1428%2018.1968%2015.7762%2016.8363L20.4693%2021.5304C20.539%2021.6%2020.6218%2021.6553%2020.7128%2021.693C20.8038%2021.7307%2020.9014%2021.7501%2021%2021.7501C21.0985%2021.7501%2021.1961%2021.7307%2021.2871%2021.693C21.3782%2021.6553%2021.4609%2021.6%2021.5306%2021.5304C21.6003%2021.4607%2021.6556%2021.3779%2021.6933%2021.2869C21.731%2021.1959%2021.7504%2021.0983%2021.7504%2020.9997C21.7504%2020.9012%2021.731%2020.8036%2021.6933%2020.7126C21.6556%2020.6215%2021.6003%2020.5388%2021.5306%2020.4691ZM3.74997%2010.4997C3.74997%209.1647%204.14585%207.85966%204.88755%206.74963C5.62925%205.63959%206.68346%204.77443%207.91686%204.26354C9.15026%203.75265%2010.5075%203.61897%2011.8168%203.87943C13.1262%204.13988%2014.3289%204.78275%2015.2729%205.72675C16.2169%206.67076%2016.8598%207.87349%2017.1203%209.18287C17.3807%2010.4922%2017.2471%2011.8494%2016.7362%2013.0828C16.2253%2014.3162%2015.3601%2015.3704%2014.2501%2016.1121C13.14%2016.8538%2011.835%2017.2497%2010.5%2017.2497C8.71037%2017.2477%206.99463%2016.5359%205.72919%2015.2705C4.46375%2014.0051%203.75195%2012.2893%203.74997%2010.4997Z%22%20fill%3D%22%239b9b9b%22%2F%3E%3C%2Fsvg%3E');
          background-position: center right 0.25rem;
          background-repeat: no-repeat;
          padding-right: 2rem;
        }
      `};

      ${validationPseudoClassUtility};
      ${helperTextUtility};

      // ----------------------------------------------
      // hack to fix the height of type=date field
      // ----------------------------------------------
      &[type='date'] {
        min-height: 35.5px;
        max-height: 40px;

        padding-top: calc(0.375rem - 1px);
        padding-bottom: calc(0.375rem - 1px);
      }

      // Sizing
      ${sizing === 'base' &&
      css`
        & {
          //
        }
      `};
    `;
  }};
`;
