import React from 'react';
import theme from '@/styles/theme';
import { SVGIconType } from '@/components/icons/svg-icon-types';

export interface WarningCircleProps extends SVGIconType {}

const WarningCircleIcon = ({ width = 16, color, ...props }: WarningCircleProps) => {
  let defaultColor = color ? theme.color[color] : 'black';
  let height = props.height || width;
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 3.25C13.4783 3.25 11.0132 3.99777 8.91648 5.39876C6.81976 6.79975 5.18556 8.79103 4.22054 11.1208C3.25552 13.4505 3.00303 16.0141 3.49499 18.4874C3.98696 20.9607 5.20127 23.2325 6.98439 25.0156C8.76751 26.7987 11.0393 28.0131 13.5126 28.505C15.9859 28.997 18.5495 28.7445 20.8792 27.7795C23.209 26.8144 25.2003 25.1802 26.6012 23.0835C28.0022 20.9868 28.75 18.5217 28.75 16C28.746 12.6197 27.4015 9.379 25.0112 6.98877C22.621 4.59854 19.3803 3.25397 16 3.25ZM16 27.25C13.775 27.25 11.5999 26.5902 9.74984 25.354C7.89979 24.1179 6.45785 22.3609 5.60636 20.3052C4.75488 18.2495 4.53209 15.9875 4.96617 13.8052C5.40025 11.6229 6.47171 9.61839 8.04505 8.04505C9.6184 6.47171 11.623 5.40025 13.8052 4.96617C15.9875 4.53208 18.2495 4.75487 20.3052 5.60636C22.3609 6.45784 24.1179 7.89978 25.354 9.74984C26.5902 11.5999 27.25 13.775 27.25 16C27.2467 18.9827 26.0604 21.8422 23.9513 23.9513C21.8422 26.0604 18.9827 27.2467 16 27.25ZM15.25 17V10C15.25 9.80109 15.329 9.61032 15.4697 9.46967C15.6103 9.32902 15.8011 9.25 16 9.25C16.1989 9.25 16.3897 9.32902 16.5303 9.46967C16.671 9.61032 16.75 9.80109 16.75 10V17C16.75 17.1989 16.671 17.3897 16.5303 17.5303C16.3897 17.671 16.1989 17.75 16 17.75C15.8011 17.75 15.6103 17.671 15.4697 17.5303C15.329 17.3897 15.25 17.1989 15.25 17ZM17.25 21.5C17.25 21.7472 17.1767 21.9889 17.0393 22.1945C16.902 22.4 16.7068 22.5602 16.4784 22.6549C16.25 22.7495 15.9986 22.7742 15.7561 22.726C15.5137 22.6777 15.2909 22.5587 15.1161 22.3839C14.9413 22.2091 14.8223 21.9863 14.774 21.7439C14.7258 21.5014 14.7505 21.2501 14.8452 21.0216C14.9398 20.7932 15.1 20.598 15.3055 20.4607C15.5111 20.3233 15.7528 20.25 16 20.25C16.3315 20.25 16.6495 20.3817 16.8839 20.6161C17.1183 20.8505 17.25 21.1685 17.25 21.5Z"
        fill={defaultColor}
      />
    </svg>
  );
};

export default WarningCircleIcon;
