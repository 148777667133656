import React from 'react';
import theme from '@/styles/theme';
import { SVGIconType } from '@/components/icons/svg-icon-types';

export interface CheckIconProps extends SVGIconType {}

const CheckIcon = ({ width = 16, color, ...props }: CheckIconProps) => {
  let defaultColor = color ? theme.color[color] : 'black';
  let height = props.height || width;
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5301 9.53L12.5301 25.53C12.3895 25.6705 12.1988 25.7493 12.0001 25.7493C11.8013 25.7493 11.6107 25.6705 11.4701 25.53L4.47009 18.53C4.33761 18.3878 4.26549 18.1998 4.26892 18.0055C4.27234 17.8112 4.35106 17.6258 4.48847 17.4884C4.62588 17.351 4.81127 17.2723 5.00557 17.2688C5.19987 17.2654 5.38792 17.3375 5.53009 17.47L12.0001 23.9387L27.4701 8.47C27.6123 8.33752 27.8003 8.2654 27.9946 8.26882C28.1889 8.27225 28.3743 8.35097 28.5117 8.48838C28.6491 8.62579 28.7278 8.81118 28.7313 9.00548C28.7347 9.19978 28.6626 9.38782 28.5301 9.53Z"
        fill={defaultColor}
      />
    </svg>
  );
};

export default CheckIcon;
